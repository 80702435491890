/* FONTS */

@import url("https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100&display=swap');

/* GLOBAL STYLES */

:root {
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Arvo', serif;
  --font-code: 'Comfortaa'; /*, monospace;*/
  --font-fredoka: 'Fredoka One', cursive;
  --font-acme: 'Acme', sans-serif;
  --font-sarabun: 'Sarabun', sans-serif;
  --cont-bg-color: #4a5bbb;
  --font-color: #ffffff;
  --btn-hov-color: #42b3ff;
  --btn-border-color: #ffffff;
  --white-bg-color: #ffffff;
  --grey-bg-color: #e4e4e4;
  --drkgrey-bg-color: #a3a3a3;
  --blue-bg-color: #08002e;
  --blue-txt-color: #08002e;
  --footer-font-color: #000;
  --btn-bg-color: #42b3ff;;
  --accent-color: #3892ce;
  --header-shadow: #000 0px 0px 5px;
  --grey-background-color: #F3F3F3;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body >* {
  margin: 0;
  max-height: 100vh;
  display: inline-block;
} 

main {
  top: 0px;
  bottom: 50px;
  height: fit-content;
}

.image {
  flex: 0 0 30%;
  margin: 5px;
}

img {
  max-width: 100%;
}

h1{
  color: var(--blue-txt-color);
  font-family: var(--font-sarabun);
  font-weight: 200;
  position: relative;
  left: 10px;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2{
  color: var(--blue-txt-color);
  font-family: var(--font-sarabun);
  font-weight: 100;
  margin: 2rem 0;
  font-size: 3.5rem;
  text-align: center;
}

h3{
  font-family: var(--font-sarabun);
}

h4{
    color: var(--blue-txt-color);
    font-family: var(--font-sarabun);
    margin: 2rem 0;
    font-size: 1.2rem;
    font-weight: 800;
    text-align: center;
}

html, body {
  background-color: var(--white-bg-color);
  display: flex;
  flex-flow: column;
  height: 100%;
  color: var(--blue-txt-color);
  font-family: var(--font-sarabun);
  margin: 0;
  padding: 0;
  position: sticky;
}

.container {
  background-color: var(--white-bg-color);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem 2rem;
  text-align: center;
}

a { 
  color: var(--blue-txt-color); 
  text-decoration: none;
}

a:visited { 
  color: var(--blue-txt-color); 
  text-decoration: none; 
}

a:hover { 
  color: var(--blue-txt-color);
  text-decoration: underline; 
}

/* HEADER */

header {
  box-shadow: var(--header-shadow);
  width: 100%;
  position:fixed;
  top:0;
  z-index: 1;
}

header .container {
  font-family: var(--font-sarabun);
  font-weight: 800;
  background-color: var(--white-bg-color);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  max-width: 100%;
  margin: 0px;
}


.logo1 {
  display: block;
}

.logo2{
  display: none;
}



.menu {
  display: flex;
  gap: 2rem;
  align-items: center;
  position: absolute;
  right: 30px;
}

nav a{
  padding: 10px;
}

#top.container{
  max-width: 100%;
  margin-top: 130px;
  padding: 0px;
}

/* JOIN */

#join-tribe div{
  text-align: center;
}


#join.container{ 
  background-color: var(--white-bg-color);
  color: var(--blue-txt-color);
  width: 100%;
  text-align: center;
  margin: auto;
}

/* BUTTONS */

.btn {
  width: 160px;
  height: 160px;
  display: block;
  margin:auto;
  padding: 1rem 1rem;
  color: var(--blue-txt-color);
  font-family: var(--font-sarabun);
  font-weight: bold;
  font-size: 1rem;
  text-transform: capitalize;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  outline: none;
  z-index: 0;
  
  background: var(--white-bg-color);
  background-image: url("../public/images/join/blue_flower.png");
  background-repeat: no-repeat;
  background-size: 160px;
  background-position-x: center;
}

.btn:hover {
  color: var(--font-color);
  border-color: var(--font-color);

  background: var(--white-bg-color);
  background-image: url("../public/images/join/pink_flower.png");
  background-repeat: no-repeat;
  background-size: 110px;
  background-position-x: center;
  background-position-y: center;
}


/*
.wallet-btn {
  margin-left: 2rem;
  color: var(--font-color);
  background: var(--btn-bg-color);
  font-family: var(--font-sarabun);
}

.hero-btn {
  font-size: 1.2rem;
  padding: 5rem 5rem;
}

a.hero-btn {
  display:block;
}
*/


/* ROADMAP */
/*
.roadmapbanner h2 {
  margin: 0;
}

.roadmapbanner{
  margin: 0;
}

.roadmapimage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0;
}
*/

/* HIGHLIGHTS */

.column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* 404 ERROR */
.errorpage h2 {
  color: var(--blue-txt-color);
}

.errorpage img{
  border-radius: 50px;
  width: 150px;
}

#errormessage.container{
  max-height: 100%;
  vertical-align: middle;
  padding-top: 30px;
}

#errorfooter.container{
  background-color: var(--white-bg-color);
  color: var(--footer-font-color);
  font-family: var(--font-sarabun);
  max-width: 100%;
  margin: 0px;
  position: fixed;
  bottom: 0;
}

.errorpage{
    height: 100%;
}

/* TECHNICAL */

#technical.container{
  background-color: var(--grey-background-color);
  max-width: 100%;
  margin: 0px;
}

/* FOOTER */

#footer.container{
  background-color: var(--white-bg-color);
  color: var(--footer-font-color);
  font-family: var(--font-sarabun);
  max-width: 100%;
  margin: 0px;
  overflow: hidden;
  bottom: 0;
}

footer {
  bottom: 0;
}


/* OWNER STYLES */

.owner-status {
  font-size: 2rem;
  text-align: center;
}

/* Media queries - Responsive timeline on screens less than 820px wide */

@media screen and (max-width: 820px) {

  .menu {
    gap: 0.5rem;
    right: 10px;
  }

  nav a {
    padding: 5px;
  }

}

@media screen and (max-width: 768px) {

  .logo1{
    display: block;
    height: 100px;
  }

}

@media screen and (max-width: 712px) {

  .logo1{
    display: none;
  }

  .logo2{
    display: block;
    height: 100px;
  }

  #join.container{
    margin: auto;
    text-align: center;
  }

}



@media screen and (max-width: 414px) {

  .menu {
    gap: 0.5rem;
    right: 10px;
  }

  nav a {
    padding: 5px;
  }

  .technical h4{
    font-size: 0.9rem;
  }

}


@media screen and (max-width: 390px) {

  .technical h4{
    font-size: 13px;
  }

}


@media screen and (max-width: 375px) {
  #root{
    max-height: 100vh;
  }

  .logo1 {
    display: none;
  }

  .logo2{
    display: block;
    height: 90px;
  }

  #top.container {
    margin-top: 119px;
  }

  .menu {
    size: 0.5rem;
    gap: 0.5rem;
    right: 10px;

    display: flex;
  
    align-items: center;
    position: absolute;
  
  }

  .menu a{
    font-size: 0.9rem;
    right: 3px;
  }


  nav {
    padding: 0.6px;
    gap: 0.1rem;
    right: 3px;
  }

  nav a{
    padding: 3px;
  }

  nav img{
    width: 18px;
    height: 18px;
  }

  h2 {
    font-size: 1.5rem;
  }

  /* JOIN */

  #join.container{
    margin: auto;
    text-align: center;
  }

  .btn {
    width: 120px;
    height: 120px;
    background-size: 120px;
  }


  /* TECHNICAL */

  .technical h4{
    font-size: 0.8rem;
    margin: 0.5rem 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  } 

  h4 a{
    display: inline-block
  }

  #technical.container{
    padding: 0.2rem 0.2rem;
  }


  /* FOOTER */

  footer{
    font-size: 0.9rem;
  }

  #footer.container{
    background-color: var(--white-bg-color);
    color: var(--footer-font-color);
    font-family: var(--font-sarabun);
    max-width: 100%;
    margin: 0px;
    overflow: hidden;
    bottom: 0;
  }

}


@media screen and (max-width: 360px) {

  .logo2{
    display: block;
    height: 87px;
  }

}

@media screen and (max-width: 320px) {

  .menu {
    gap: 0.5rem;
    right: 10px;
  }

}

@media screen and (max-width: 280px) {


  header .container {
    padding: 1rem 0rem;
  }

  .menu {
    gap: 0.5rem;
    right: 4px;
  }

  nav a {
    padding: 5px;
  }

  .technical h4{
    font-size: 12px;
  }

}






