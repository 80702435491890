/* FONTS */

@import url("https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100&display=swap');


html, body {
  margin: 0;
  padding: 0;
  position: sticky;
}

#gallerytop.container{
  width: 250px;
  margin-top: 150px;
  padding: 0px;
  justify-content: center;
}

.gallerylogo1{
  display: block;
}

.gallerylogo2{
  display: none;
}

#gallerytop img{
  width: 350px;
  height: auto;
  padding-bottom: 30px;
  padding-top: 20px;
}



/** GALLERY IMAGES **/

#gallerycontainer.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem 2rem;
  text-align: center;
  background-color: var(--grey-background-color);
  overflow: auto;
}

.col {
  float: left;
  width: auto;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 15px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

div.gallery img {
  border-top-left-radius: 54%;
  border-top-right-radius: 15%;
  border-bottom-right-radius: 54%;
  border-bottom-left-radius: 21%;
  width: 300px;
  max-width: none;
}

p {
  font-weight: 800;
}


/* Media queries - Responsive timeline on screens less than 820px wide */

@media screen and (max-width: 820px) {

  .menu {
    gap: 0.5rem;
    right: 10px;
  }

  nav a {
    padding: 5px;
  }

}

@media screen and (max-width: 768px) {

  .gallerylogo1{
    display: block;
    height: 100px;
  }

}

@media screen and (max-width: 712px) {

  .gallerylogo1{
    display: none;
  }

  .gallerylogo2{
    display: block;
    height: 100px;
  }

  #join.container{
    margin: auto;
    text-align: center;
  }

}



@media screen and (max-width: 414px) {

  .menu {
    gap: 0.5rem;
    right: 10px;
  }

  nav a {
    padding: 5px;
  }

  .technical h4{
    font-size: 0.9rem;
  }

}


@media screen and (max-width: 390px) {

  .technical h4{
    font-size: 13px;
  }

}


@media screen and (max-width: 375px) {
  #root{
    max-height: 100vh;
  }

  .gallerylogo1 {
    display: none;
  }

  .gallerylogo2{
    display: block;
    height: 90px;
  }

  #top.container {
    margin-top: 119px;
  }

  .menu {
    size: 0.5rem;
    gap: 0.5rem;
    right: 10px;

    display: flex;
  
    align-items: center;
    position: absolute;
  
  }

  .menu a{
    font-size: 0.9rem;
    right: 3px;
  }


  nav {
    padding: 0.6px;
    gap: 0.1rem;
    right: 3px;
  }

  nav a{
    padding: 3px;
  }

  nav img{
    width: 18px;
    height: 18px;
  }

  h2 {
    font-size: 1.5rem;
  }

  /* JOIN */

  #join.container{
    margin: auto;
    text-align: center;
  }

  .btn {
    width: 120px;
    height: 120px;
    background-size: 120px;
  }


  /* TECHNICAL */

  .technical h4{
    font-size: 0.8rem;
    margin: 0.5rem 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  } 

  h4 a{
    display: inline-block
  }

  #technical.container{
    padding: 0.2rem 0.2rem;
  }


  /* FOOTER */

  footer{
    font-size: 0.9rem;
  }

  #footer.container{
    background-color: var(--white-bg-color);
    color: var(--footer-font-color);
    font-family: var(--font-sarabun);
    max-width: 100%;
    margin: 0px;
    overflow: hidden;
    bottom: 0;
  }

}


@media screen and (max-width: 360px) {

  .gallerylogo2{
    display: block;
    height: 87px;
  }

}

@media screen and (max-width: 320px) {

  .menu {
    gap: 0.5rem;
    right: 10px;
  }

  div.gallery img {
    width: 220px;
  }


}

@media screen and (max-width: 280px) {


  header .container {
    padding: 1rem 0rem;
  }

  .menu {
    gap: 0.5rem;
    right: 4px;
  }

  nav a {
    padding: 5px;
  }

  div.gallery img {
    width: 220px;
  }

  .technical h4{
    font-size: 12px;
  }

}









